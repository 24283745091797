import { setAuthError } from "features/authentication/authSlice";
import { removeAuthToken } from "features/authentication/authSlice";
import { useDispatch, useSelector } from "react-redux";
import Wave from "../../assets/img/wave.png";
import { MenuIcon } from "@heroicons/react/outline";
import { showSideBar } from "features/utils/utilSlice";
import "./index.css";
import WalletTransactions from "views/wallets/detail";
import { useLocation } from "react-router";
import { useEffect, useState } from "react";
import { findTransactions } from "./api";

const DownlineTransactions = () => {
  const dispatch = useDispatch();
  const mobileBar = useSelector((state) => state.utility.mobileBar);
  const location = useLocation();
  const { wallet } = location.state;

  const [isLoading, setIsLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);

  let loadTransactions = async (account) => {
    setIsLoading(true);
    try {
      let response = await findTransactions(wallet.id);
      if (response.status !== "SUCCESS") {
      } else {
        setTransactions(response.data.transactions);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (wallet) {
      loadTransactions();
    }
    return () => {};
  }, [wallet]);

  return (
    <div>
      <div
        className="header-bg w-screen md:w-full"
        style={{
          backgroundImage: `url(${Wave})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="flex justify-between py-8 px-4 md:px-12 header-bg">
          <div className="flex">
            <div
              className="cursor-pointer block sm:hidden"
              onClick={() => {
                dispatch(showSideBar(!mobileBar));
              }}
            >
              <MenuIcon className="h-8 w-8 " />
            </div>
            <div>
              <div className="font-500 text-2xl">
                {wallet.title} transactions
              </div>
              <div className="pt-4 text-base ">
                Search for a specific transaction
              </div>

              <div className="flex mt-10 gap-4">
                <input
                  className="h-18 bg-white appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-green-500"
                  placeholder="Enter value"
                />
              </div>

              <button className="mt-4 mb-4 btn-primary text-white tbutton px-8 py-2 rounded">
                Search
              </button>
            </div>
          </div>
          <div className="flex gap-4 h-12 pt-1 mt-1">
            <button
              onClick={(e) => {
                e.preventDefault();
                dispatch(removeAuthToken());
                dispatch(setAuthError(""));
              }}
              className="text-xs md:text-base bg-red-500 hover:bg-red-500 text-white font-semibold hover:text-white py-2 px-2 md:px-4 hover:border-transparent rounded whitespace-nowrap"
            >
              Sign Out
            </button>
          </div>
        </div>
      </div>

      <WalletTransactions transactions={transactions} />
    </div>
  );
};

export default DownlineTransactions;
