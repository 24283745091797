import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import "./index.css";
import x from "../../assets/svg/X.svg";
import { verifyNuban, withdrawNuban } from "./api";
import { useSelector } from "react-redux";

export default function WithdrawModal({ open, setOpen }) {
  const [isVerifying, setIsVerifying] = useState(false);
  const [isWithdrawing, setIsWithdrawing] = useState(false);
  const [response, setResponse] = useState("");
  const wallets = useSelector((state) => state.auth.wallets);

  const cancelButtonRef = useRef(null);

  let findVerifyWithdrawal = async () => {
    setResponse("");
    setIsVerifying(true);
    try {
      let response = await verifyNuban({
        nuban: withdrawal.nuban,
        bank: withdrawal.bank,
      });
      if (response.status !== "SUCCESS") {
        setResponse(response.message);
      } else {
        setWithdrawal({
          ...withdrawal,
          recipient: response.data.recipient,
          name: response.data.name,
          verified: true,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsVerifying(false);
    }
  };

  let makeWithdrawal = async () => {
    setIsWithdrawing(true);
    try {
      let response = await withdrawNuban({
        amount: withdrawal.amount.toString(),
        recipient: withdrawal.nuban,
        wallet: wallets[0].id,
        bank: withdrawal.bank,
      });
      if (response.status !== "SUCCESS") {
        setResponse(response.message);
      } else {
        setResponse("Success!!! Your transfer is been processed.");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsWithdrawing(false);
    }
  };

  const [withdrawal, setWithdrawal] = useState({
    recipient: "",
    amount: 0.0,
    bank: "",
    nuban: "",
    name: "",
    verified: false,
  });

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="flex items-start">
                  <div className="mt-3 sm:mt-0 sm:ml-4 sm:mr-4 w-full">
                    <img
                      className="close"
                      src={x}
                      onClick={() => setOpen(false)}
                      alt=""
                    />
                    <Dialog.Title
                      as="h3"
                      className="pt-10 text-lg leading-6 font-medium dialog--title"
                    >
                      Bank withdrawal
                    </Dialog.Title>
                    <div className="mt-6 w-full">
                      <p className="text-sm text-gray-500 dialog--subtitle">
                        <div className="py-1">
                          <select
                            className="h-18 bg-green-200 appearance-none border-2 border-green-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-green-200 focus:border-green-200"
                            required
                            name="bank"
                            placeholder="Winning wallet"
                          >
                            <option>Winning Wallet</option>
                          </select>
                        </div>

                        <div className="py-2">
                          <select
                            className="h-18  appearance-none border-2  rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-green-200 focus:border-green-200"
                            required
                            name="Select Bank"
                            onChange={(e) =>
                              setWithdrawal({
                                ...withdrawal,
                                bank: e.target.value,
                              })
                            }
                          >
                            <option defaultValue="">Select Bank</option>
                            <option value="000003">FCMB</option>
                            <option value="000002">Keystone Bank</option>
                            <option value="000018">Union Bank</option>
                            <option value="000005">Diamond Bank</option>
                            <option value="000001">Sterling Bank</option>
                            <option value="000004">
                              United Bank for Africa
                            </option>
                            <option value="000006">JAIZ Bank</option>
                            <option value="000007">Fidelity Bank</option>
                            <option value="000015">Zenith Bank Plc</option>
                            <option value="000008">Polaris Bank</option>
                            <option value="000009">Citi Bank</option>
                            <option value="000010">Ecobank Bank</option>
                            <option value="000011">Unity Bank</option>
                            <option value="000012">StanbicIBTC Bank</option>
                            <option value="000013">GTBank Plc</option>
                            <option value="000014">Access Bank</option>
                            <option value="000016">
                              First Bank of Nigeria
                            </option>
                            <option value="000017">Wema Bank</option>
                            <option value="000019">Enterprise Bank</option>
                            <option value="000020">Heritage</option>
                            <option value="000021">StandardChartered</option>
                            <option value="000022">Suntrust Bank</option>
                            <option value="000023">Providus Bank</option>
                            <option value="000024">Rand Merchant Bank</option>
                            <option value="000025">Titan Trust Bank</option>
                            <option value="000026">Taj Bank</option>
                            <option value="000027">Globus Bank</option>
                            <option value="000028">
                              Central Bank of Nigeria
                            </option>
                            <option value="000029">Lotus Bank</option>
                            <option value="000031">Premium Trust Bank</option>
                            <option value="000033">eNaira</option>
                            <option value="000034">Signature Bank</option>
                            <option value="000036">Optimus Bank</option>
                            <option value="000030">Parallex Bank</option>
                            <option value="050002">
                              FEWCHORE FINANCE COMPANY LIMITED
                            </option>
                            <option value="050003">
                              SageGrey Finance Limited
                            </option>
                            <option value="050005">AAA Finance</option>
                            <option value="050006">
                              Branch International Financial Services
                            </option>
                            <option value="050007">
                              Tekla Finance Limited
                            </option>
                            <option value="050009">Fast Credit</option>
                            <option value="050010">
                              Fundquest Financial Services Limited
                            </option>
                            <option value="050012">Enco Finance</option>
                            <option value="050013">Dignity Finance</option>
                            <option value="050013">
                              Trinity Financial Services Limited
                            </option>
                            <option value="400001">FSDH Merchant Bank</option>
                            <option value="060001">
                              Coronation Merchant Bank
                            </option>
                            <option value="060002">
                              FBNQUEST Merchant Bank
                            </option>
                            <option value="060003">Nova Merchant Bank</option>
                            <option value="060004">
                              Greenwich Merchant Bank
                            </option>
                            <option value="070007">
                              Omoluabi savings and loans
                            </option>
                            <option value="090001">ASOSavings & Loans</option>
                            <option value="090005">
                              Trustbond Mortgage Bank
                            </option>
                            <option value="090006">SafeTrust</option>
                            <option value="090107">
                              FBN Mortgages Limited
                            </option>
                            <option value="100024">
                              Imperial Homes Mortgage Bank
                            </option>
                            <option value="100028">AG Mortgage Bank</option>
                            <option value="070009">
                              Gateway Mortgage Bank
                            </option>
                            <option value="070010">Abbey Mortgage Bank</option>
                            <option value="070011">Refuge Mortgage Bank</option>
                            <option value="070012">
                              Lagos Building Investment Company
                            </option>
                            <option value="070013">
                              Platinum Mortgage Bank
                            </option>
                            <option value="070014">
                              First Generation Mortgage Bank
                            </option>
                            <option value="070015">Brent Mortgage Bank</option>
                            <option value="070016">
                              Infinity Trust Mortgage Bank
                            </option>
                            <option value="070019">
                              MayFresh Mortgage Bank
                            </option>
                            <option value="090003">
                              Jubilee-Life Mortgage Bank
                            </option>
                            <option value="070017">
                              Haggai Mortgage Bank Limited
                            </option>
                            <option value="070021">Coop Mortgage Bank</option>
                            <option value="070022">STB Mortgage Bank</option>
                            <option value="070023">
                              Delta Trust Microfinance Bank
                            </option>
                            <option value="070024">
                              Homebase Mortgage Bank
                            </option>
                            <option value="070025">
                              Akwa Savings & Loans Limited
                            </option>
                            <option value="070026">FHA Mortgage Bank</option>
                            <option value="090108">New Prudential Bank</option>
                            <option value="070001">
                              NPF MicroFinance Bank
                            </option>
                            <option value="070002">
                              Fortis Microfinance Bank
                            </option>
                            <option value="070006">Covenant MFB</option>
                            <option value="070008">Page Financials</option>
                            <option value="090004">
                              Parralex Microfinance bank
                            </option>
                            <option value="090097">Ekondo MFB</option>
                            <option value="090110">VFD MFB</option>
                            <option value="090111">
                              FinaTrust Microfinance Bank
                            </option>
                            <option value="090112">
                              Seed Capital Microfinance Bank
                            </option>
                            <option value="090114">Empire trust MFB</option>
                            <option value="090115">TCF MFB</option>
                            <option value="090130">
                              Consumer Microfinance Bank
                            </option>
                            <option value="090131">
                              Allworkers Microfinance Bank
                            </option>
                            <option value="090132">
                              Richway Microfinance Bank
                            </option>
                            <option value="090133">
                              AL-Barakah Microfinance Bank
                            </option>
                            <option value="090134">
                              Accion Microfinance Bank
                            </option>
                            <option value="090135">
                              Personal Trust Microfinance Bank
                            </option>
                            <option value="090136">
                              Microcred Microfinance Bank
                            </option>
                            <option value="090137">
                              PecanTrust Microfinance Bank
                            </option>
                            <option value="090138">
                              Royal Exchange Microfinance Bank
                            </option>
                            <option value="090139">
                              Visa Microfinance Bank
                            </option>
                            <option value="090140">
                              Sagamu Microfinance Bank
                            </option>
                            <option value="090141">
                              Chikum Microfinance Bank
                            </option>
                            <option value="090142">
                              Yes Microfinance Bank
                            </option>
                            <option value="090143">
                              Apeks Microfinance Bank
                            </option>
                            <option value="090144">
                              CIT Microfinance Bank
                            </option>
                            <option value="090145">
                              Fullrange Microfinance Bank
                            </option>
                            <option value="090146">
                              Trident Microfinance Bank
                            </option>
                            <option value="090147">
                              Hackman Microfinance Bank
                            </option>
                            <option value="090148">
                              Bowen Microfinance Bank
                            </option>
                            <option value="090149">
                              IRL Microfinance Bank
                            </option>
                            <option value="090150">
                              Virtue Microfinance Bank
                            </option>
                            <option value="090151">
                              Mutual Trust Microfinance Bank
                            </option>
                            <option value="090152">
                              Nagarta Microfinance Bank
                            </option>
                            <option value="090153">
                              FFS Microfinance Bank
                            </option>
                            <option value="090154">
                              CEMCS Microfinance Bank
                            </option>
                            <option value="090155">
                              Advans La Fayette Microfinance Bank
                            </option>
                            <option value="090156">
                              e-Barcs Microfinance Bank
                            </option>
                            <option value="090157">
                              Infinity Microfinance Bank
                            </option>
                            <option value="090158">
                              Futo Microfinance Bank
                            </option>
                            <option value="090159">
                              Credit Afrique Microfinance Bank
                            </option>
                            <option value="090160">
                              Addosser Microfinance Bank
                            </option>
                            <option value="090161">
                              Okpoga Microfinance Bank
                            </option>
                            <option value="090162">
                              Stanford Microfinance Bak
                            </option>
                            <option value="090164">
                              First Royal Microfinance Bank
                            </option>
                            <option value="090165">
                              Petra Microfinance Bank
                            </option>
                            <option value="090166">
                              Eso-E Microfinance Bank
                            </option>
                            <option value="090167">
                              Daylight Microfinance Bank
                            </option>
                            <option value="090168">
                              Gashua Microfinance Bank
                            </option>
                            <option value="090169">
                              Alpha Kapital Microfinance Bank
                            </option>
                            <option value="090171">
                              Mainstreet Microfinance Bank
                            </option>
                            <option value="090172">
                              Astrapolaris Microfinance Bank
                            </option>
                            <option value="090173">
                              Reliance Microfinance Bank
                            </option>
                            <option value="090174">
                              Malachy Microfinance Bank
                            </option>
                            <option value="090175">
                              HighStreet Microfinance Bank
                            </option>
                            <option value="090176">
                              Bosak Microfinance Bank
                            </option>
                            <option value="090177">
                              Lapo Microfinance Bank
                            </option>
                            <option value="090178">
                              GreenBank Microfinance Bank
                            </option>
                            <option value="090179">
                              FAST Microfinance Bank
                            </option>
                            <option value="090180">
                              Amju Unique Microfinance Bank
                            </option>
                            <option value="090186">
                              Girei Microfinance Bank
                            </option>
                            <option value="090188">
                              Baines Credit Microfinance Bank
                            </option>
                            <option value="090189">
                              Esan Microfinance Bank
                            </option>
                            <option value="090190">
                              Mutual Benefits Microfinance Bank
                            </option>
                            <option value="090191">
                              KCMB Microfinance Bank
                            </option>
                            <option value="090192">
                              Midland Microfinance Bank
                            </option>
                            <option value="090193">
                              Unical Microfinance Bank
                            </option>
                            <option value="090194">
                              NIRSAL Microfinance Bank
                            </option>
                            <option value="090195">
                              Grooming Microfinance Bank
                            </option>
                            <option value="090196">
                              Pennywise Microfinance Bank
                            </option>
                            <option value="090197">
                              ABU Microfinance Bank
                            </option>
                            <option value="090198">
                              RenMoney Microfinance Bank
                            </option>
                            <option value="090205">
                              New Dawn Microfinance Bank
                            </option>
                            <option value="090251">UNN MFB</option>
                            <option value="090252">
                              Yobe Microfinance Bank
                            </option>
                            <option value="090254">
                              Coalcamp Microfinance Bank
                            </option>
                            <option value="090258">
                              Imo State Microfinance Bank
                            </option>
                            <option value="090259">
                              Alekun Microfinance Bank
                            </option>
                            <option value="090260">
                              Above Only Microfinance Bank
                            </option>
                            <option value="090261">
                              Quickfund Microfinance Bank
                            </option>
                            <option value="090262">
                              Stellas Microfinance Bank
                            </option>
                            <option value="090263">
                              Navy Microfinance Bank
                            </option>
                            <option value="090264">
                              Auchi Microfinance Bank
                            </option>
                            <option value="090265">
                              Lovonus Microfinance Bank
                            </option>
                            <option value="090266">
                              Uniben Microfinance Bank
                            </option>
                            <option value="090267">
                              Kuda Microfinance Bank
                            </option>
                            <option value="090268">
                              Adeyemi College Staff Microfinance Bank
                            </option>
                            <option value="090269">
                              Greenville Microfinance Bank
                            </option>
                            <option value="090270">AB Microfinance Bank</option>
                            <option value="090271">
                              Lavender Microfinance Bank
                            </option>
                            <option value="090272">
                              Olabisi Onabanjo University Microfinance Bank
                            </option>
                            <option value="090273">
                              Emeralds Microfinance Bank
                            </option>
                            <option value="090274">
                              Prestige Microfinance Bank
                            </option>
                            <option value="090276">
                              Trustfund Microfinance Bank
                            </option>
                            <option value="090277">
                              Al-Hayat Microfinance Bank
                            </option>
                            <option value="090278">
                              Glory Microfinance Bank
                            </option>
                            <option value="090279">
                              Ikire Microfinance Bank
                            </option>
                            <option value="090280">
                              Megapraise Microfinance Bank
                            </option>
                            <option value="090281">
                              MintFinex Microfinance Bank
                            </option>
                            <option value="090282">
                              Arise Microfinance Bank
                            </option>
                            <option value="090283">
                              Nnew Women Microfinance Bank
                            </option>
                            <option value="090285">
                              First Option Microfinance Bank
                            </option>
                            <option value="090286">
                              Safe Haven Microfinance Bank
                            </option>
                            <option value="090287">
                              AssetMatrix Microfinance Bank
                            </option>
                            <option value="090289">
                              Pillar Microfinance Bank
                            </option>
                            <option value="090290">
                              FCT Microfinance Bank
                            </option>
                            <option value="090291">
                              Halal Credit Microfinance Bank
                            </option>
                            <option value="090292">
                              Afekhafe Microfinance Bank
                            </option>
                            <option value="090293">
                              Brethren Microfinance Bank
                            </option>
                            <option value="090294">
                              Eagle Flight Microfinance Bank
                            </option>
                            <option value="090295">
                              Omiye Microfinance Bank
                            </option>
                            <option value="090296">
                              Polyunwana Microfinance Bank
                            </option>
                            <option value="090297">
                              Alert Microfinance Bank
                            </option>
                            <option value="090298">
                              FedPoly Nasarawa Microfinance Bank
                            </option>
                            <option value="090299">
                              Kontagora Microfinance Bank
                            </option>
                            <option value="090303">
                              Purplemoney Microfinance Bank
                            </option>
                            <option value="090304">
                              Evangel Microfinance Bank
                            </option>
                            <option value="090305">
                              Sulspap Microfinance Bank
                            </option>
                            <option value="090307">
                              Aramoko Microfinance Bank
                            </option>
                            <option value="090308">
                              Brightway Microfinance Bank
                            </option>
                            <option value="090310">
                              EdFin Microfinance Bank
                            </option>
                            <option value="090315">
                              U & C Microfinance Bank
                            </option>
                            <option value="090317">
                              PatrickGold Microfinance Bank
                            </option>
                            <option value="090318">
                              Federal University Dutse Microfinance Bank
                            </option>
                            <option value="090320">
                              KadPoly Microfinance Bank
                            </option>
                            <option value="090321">
                              MayFair Microfinance Bank
                            </option>
                            <option value="090322">
                              Rephidim Microfinance Bank
                            </option>
                            <option value="090323">
                              Mainland Microfinance Bank
                            </option>
                            <option value="090324">
                              Ikenne Microfinance Bank
                            </option>
                            <option value="090325">Sparkle</option>
                            <option value="090326">
                              Balogun Gambari Microfinance Bank
                            </option>
                            <option value="090327">
                              Trust Microfinance Bank
                            </option>
                            <option value="090328">Eyowo</option>
                            <option value="090329">
                              Neptune Microfinance Bank
                            </option>
                            <option value="090331">
                              UNAAB Microfinance Bank
                            </option>
                            <option value="090332">
                              Evergreen Microfinance Bank
                            </option>
                            <option value="090333">
                              Oche Microfinance Bank
                            </option>
                            <option value="090337">
                              Iyeru Okin Microfinance Bank
                            </option>
                            <option value="090352">
                              Jessefield Microfinance Bank
                            </option>
                            <option value="090336">
                              BIPC Microfinance Bank
                            </option>
                            <option value="090345">
                              OAU Microfinance Bank
                            </option>
                            <option value="090349">
                              Nassarawa Microfinance Bank
                            </option>
                            <option value="090360">
                              CashConnect Microfinance Bank
                            </option>
                            <option value="090362">
                              Molusi Microfinance Bank
                            </option>
                            <option value="090363">
                              Headway Microfinance Bank
                            </option>
                            <option value="090364">
                              Nuture Microfinance Bank
                            </option>
                            <option value="090365">
                              Corestep Microfinance Bank
                            </option>
                            <option value="090366">
                              Firmus Microfinance Bank
                            </option>
                            <option value="090369">
                              Seedvest Microfinance Bank
                            </option>
                            <option value="090370">
                              Ilisan Microfinance Bank
                            </option>
                            <option value="090372">
                              Legend Microfinance Bank
                            </option>
                            <option value="090373">
                              Think Finance Microfinance Bank
                            </option>
                            <option value="090374">
                              Coastline Microfinance Bank
                            </option>
                            <option value="090376">
                              Apple Microfinance Bank
                            </option>
                            <option value="090377">
                              Isaleoyo Microfinance Bank
                            </option>
                            <option value="090378">
                              New Golden Pastures Microfinance Bank
                            </option>
                            <option value="090385">
                              GTI Microfinance Bank
                            </option>
                            <option value="090386">
                              Interland Microfinance Bank
                            </option>
                            <option value="090389">
                              EK-Reliable Microfinance Bank
                            </option>
                            <option value="090391">
                              Davodani Microfinance Bank
                            </option>
                            <option value="090380">
                              Conpro Microfinance Bank
                            </option>
                            <option value="090393">
                              Bridgeway Microfinance Bank
                            </option>
                            <option value="090394">
                              Amac Microfinance Bank
                            </option>
                            <option value="090395">
                              Borgu Microfinance Bank
                            </option>
                            <option value="090396">
                              Oscotech Microfinance Bank
                            </option>
                            <option value="090399">
                              Nwannegadi Microfinance Bank
                            </option>
                            <option value="090398">
                              Federal Polytechnic Nekede Microfinance Bank
                            </option>
                            <option value="090401">
                              Shepherd Trust Microfinance Bank
                            </option>
                            <option value="090403">
                              UDA Microfinance Bank
                            </option>
                            <option value="090404">
                              Olowolagba Microfinance Bank
                            </option>
                            <option value="090405">
                              Moniepoint Microfinance Bank
                            </option>
                            <option value="090406">
                              Business Support Microfinance Bank
                            </option>
                            <option value="090409">FCMB BETA</option>
                            <option value="090408">
                              GMB Microfinance Bank
                            </option>
                            <option value="090410">
                              Maritime Microfinance Bank
                            </option>
                            <option value="090411">
                              Giginya Microfinance bank
                            </option>
                            <option value="090412">
                              Preeminent Microfinance Bank
                            </option>
                            <option value="090433">
                              Rigo Microfinance Bank
                            </option>
                            <option value="090444">
                              BOI Microfinance Bank
                            </option>
                            <option value="090448">
                              Moyofade Microfinance Bank
                            </option>
                            <option value="090455">
                              Mkobo Microfinance Bank
                            </option>
                            <option value="090463">
                              Rehoboth Microfinance Bank
                            </option>
                            <option value="090464">
                              Unimaid Microfinance Bank
                            </option>
                            <option value="090468">
                              OLOFIN OWENA Microfinance Bank
                            </option>
                            <option value="090473">
                              Assets Microfinance Bank
                            </option>
                            <option value="090338">
                              UniUyo Microfinance Bank
                            </option>
                            <option value="090466">
                              YCT Microfinance Bank
                            </option>
                            <option value="090467">
                              Good Neigbours Microfinance Bank
                            </option>
                            <option value="090471">
                              Oluchukwu Microfinance Bank
                            </option>
                            <option value="090465">
                              Maintrust Microfinance Bank
                            </option>
                            <option value="090469">
                              Aniocha Microfinance bank
                            </option>
                            <option value="090472">
                              Caretaker Microfinance Bank
                            </option>
                            <option value="090475">
                              Giant Stride Microfinance Bank
                            </option>
                            <option value="090181">
                              Balogun Fulani Microfinance Bank
                            </option>
                            <option value="090474">
                              Verdant Microfinance Bank
                            </option>
                            <option value="090470">
                              Changan RTS Microfinance Bank
                            </option>
                            <option value="090476">
                              Anchorage Microfinance Bank
                            </option>
                            <option value="090477">Light MFB</option>
                            <option value="090480">
                              Cintrust Microfinance Bank
                            </option>
                            <option value="090482">
                              Fedeth Microfinance Bank
                            </option>
                            <option value="090483">
                              Ada Microfinance Bank
                            </option>
                            <option value="090488">
                              Ibu-Aje Microfinance Bank
                            </option>
                            <option value="090489">
                              Alvana Microfinance Bank
                            </option>
                            <option value="090490">Chukwunenye MFB</option>
                            <option value="090491">Nsuk MFB</option>
                            <option value="090492">Oraukwu MFB</option>
                            <option value="090494">Boji MFB</option>
                            <option value="090495">
                              Goodnews Microfinance Bank
                            </option>
                            <option value="090496">
                              Randalpha Microfinance Bank
                            </option>
                            <option value="090499">
                              Pristine Divitis Microfinance Bank
                            </option>
                            <option value="090502">
                              Shalom Microfinance Bank
                            </option>
                            <option value="090503">
                              Projects Microfinance Bank
                            </option>
                            <option value="090504">
                              Zikora Microfinance Bank
                            </option>
                            <option value="090505">
                              Nigerian Prisons Microfinance Bank
                            </option>
                            <option value="090506">Solid Allianze MFB</option>
                            <option value="090507">FIMS MFB</option>
                            <option value="090513">
                              SEAP Microfinance Bank
                            </option>
                            <option value="090515">
                              RIMA Growth Pathway Microfinance Bank
                            </option>
                            <option value="090516">
                              Numo Microfinance Bank
                            </option>
                            <option value="090517">
                              Uhuru Microfinance Bank
                            </option>
                            <option value="090518">
                              Afemai Microfinance Bank
                            </option>
                            <option value="090519">
                              Iboma Fadama Microfinance Bank
                            </option>
                            <option value="090523">
                              Chase Microfinance Bank
                            </option>
                            <option value="090524">
                              Solidrock microfinance Bank
                            </option>
                            <option value="090525">
                              TripleA Microfinance Bank
                            </option>
                            <option value="090526">
                              Crescent Microfinance Bank
                            </option>
                            <option value="090527">
                              Ojokoro Microfinance Bank
                            </option>
                            <option value="090528">
                              Mgbidi Microfinance Bank
                            </option>
                            <option value="090529">
                              Ampersand Microfinance Bank
                            </option>
                            <option value="090530">Confidence MFB</option>
                            <option value="090531">
                              Aku Microfinance Bank
                            </option>
                            <option value="090534">
                              Polybadan Microfinance Bank
                            </option>
                            <option value="090536">
                              Ikoyi-Osun Microfinance Bank
                            </option>
                            <option value="090537">
                              Lobrem Microfinance Bank
                            </option>
                            <option value="090538">
                              BluePrint Investments Microfinance Bank
                            </option>
                            <option value="090539">
                              Enrich Microfinance Bank
                            </option>
                            <option value="090540">
                              Aztec Microfinance Bank
                            </option>
                            <option value="090541">
                              Excellent Microfinance Bank
                            </option>
                            <option value="090542">
                              Otuo Microfinance Bank
                            </option>
                            <option value="090543">
                              Iwoama Microfinance Bank
                            </option>
                            <option value="090544">
                              Aspire Microfinance Bank
                            </option>
                            <option value="090545">
                              Abulesoro Microfinance Bank
                            </option>
                            <option value="090546">
                              Ijebu-Ife Microfinance Bank
                            </option>
                            <option value="090547">
                              Rockshield Microfinance Bank
                            </option>
                            <option value="090548">
                              Ally Microfinance Bank
                            </option>
                            <option value="090549">KC Microfinance Bank</option>
                            <option value="090550">
                              Green Energy Microfinance Bank
                            </option>
                            <option value="090551">
                              FairMoney Microfinance Bank
                            </option>
                            <option value="090553">
                              Consistent Trust Microfinance Bank
                            </option>
                            <option value="090554">
                              Kayvee Microfinance Bank
                            </option>
                            <option value="090555">
                              BishopGate Microfinance Bank
                            </option>
                            <option value="090556">
                              Egwafin Microfinance Bank
                            </option>
                            <option value="090557">
                              Lifegate Microfinance Bank
                            </option>
                            <option value="090558">
                              Shongom Microfinance Bank
                            </option>
                            <option value="090559">
                              Shield Microfinance Bank
                            </option>
                            <option value="090560">
                              Tanadi Microfinance Bank
                            </option>
                            <option value="090561">
                              Akuchuckwu Microfinance Bank
                            </option>
                            <option value="090562">
                              Cedar Microfinance Bank
                            </option>
                            <option value="090563">
                              Balera Microfinance Bank
                            </option>
                            <option value="090564">
                              Supreme Microfinance Bank
                            </option>
                            <option value="090565">
                              Oke-Aro Oredegbe Microfinance Bank
                            </option>
                            <option value="090566">
                              Okuku Microfinance Bank
                            </option>
                            <option value="090567">
                              Orokam Microfinance Bank
                            </option>
                            <option value="090568">
                              Broadview Microfinance Bank
                            </option>
                            <option value="090569">
                              Qube Microfinance Bank
                            </option>
                            <option value="090570">
                              Iyamoye Microfinance Bank
                            </option>
                            <option value="090571">
                              Ilaro Poly Microfinance Bank
                            </option>
                            <option value="090572">
                              EWT Microfinance Bank
                            </option>
                            <option value="090573">Snow MFB</option>
                            <option value="090575">
                              First Midas Microfinance Bank
                            </option>
                            <option value="090576">
                              Octopus Microfinance Bank
                            </option>
                            <option value="090579">
                              Gbede Microfinance Bank
                            </option>
                            <option value="090580">
                              Otech Microfinance Bank
                            </option>
                            <option value="090583">
                              Stateside Microfinance Bank
                            </option>
                            <option value="090574">GOLDMAN MFB</option>
                            <option value="090535">Nkpolu-Ust MFB</option>
                            <option value="090578">Iwade MFB Ltd</option>
                            <option value="090587">Microbiz MFB</option>
                            <option value="090588">Orisun MFB</option>
                            <option value="090589">Mercury MFB</option>
                            <option value="090591">
                              Gabsyn Microfinance Bank Limited
                            </option>
                            <option value="090593">
                              Tasued Microfinance Bank
                            </option>
                            <option value="090602">
                              Kenechukwu Microfinance Bank
                            </option>
                            <option value="090950">
                              Waya Microfinance Bank
                            </option>
                            <option value="090598">
                              IBA Microfinance Bank
                            </option>
                            <option value="090584">
                              Island Microfinance Bank
                            </option>
                            <option value="090600">
                              Ave Maria Microfinance Bank
                            </option>
                            <option value="090608">
                              Akpo Microfinance Bank
                            </option>
                            <option value="090609">
                              Ummah Microfinance Bank
                            </option>
                            <option value="090610">
                              Amoye Microfinance Bank
                            </option>
                            <option value="090612">
                              Medef Microfinance Bank
                            </option>
                            <option value="090532">
                              IBOLO Microfinance Bank
                            </option>
                            <option value="090581">Banc Corp MFB</option>
                            <option value="090614">Flourish MFB</option>
                            <option value="090615">Beststar MFB</option>
                            <option value="090616">Rayyan MFB</option>
                            <option value="090603">Macrod MFB</option>
                            <option value="090620">Iyin Ekiti MFB</option>
                            <option value="090621">
                              Gidauniyar Alheri MFB
                            </option>
                            <option value="090611">Creditville MFB</option>
                            <option value="090623">MAB Allianz MFB</option>
                            <option value="090606">KKU MFB</option>
                            <option value="100001">FET</option>
                            <option value="100002">Paga</option>
                            <option value="100003">Parkway-ReadyCash</option>
                            <option value="100004">Paycom(Opay)</option>
                            <option value="100005">Cellulant</option>
                            <option value="100006">eTranzact</option>
                            <option value="100007">
                              Stanbic IBTC @ease wallet
                            </option>
                            <option value="100008">
                              Ecobank Xpress Account
                            </option>
                            <option value="100009">GTMobile</option>
                            <option value="100010">TeasyMobile</option>
                            <option value="100011">Mkudi</option>
                            <option value="100012">VTNetworks</option>
                            <option value="100013">AccessMobile</option>
                            <option value="100014">FBNMobile</option>
                            <option value="100036">Kegow (Chamsmobile)</option>
                            <option value="100016">FortisMobile</option>
                            <option value="100017">Hedonmark</option>
                            <option value="100018">ZenithMobile</option>
                            <option value="100019">Fidelity Mobile</option>
                            <option value="100020">MoneyBox</option>
                            <option value="100021">Eartholeum</option>
                            <option value="100022">GoMoney</option>
                            <option value="100023">TagPay</option>
                            <option value="100025">
                              Zinternet Nigera Limited
                            </option>
                            <option value="100026">One Finance</option>
                            <option value="100029">Innovectives Kesh</option>
                            <option value="100030">EcoMobile</option>
                            <option value="100031">FCMB Easy Account</option>
                            <option value="100032">
                              Contec Global Infotech Limited (NowNow)
                            </option>
                            <option value="100033">PalmPay Limited</option>
                            <option value="100034">Zenith Eazy Wallet</option>
                            <option value="100052">Access Yello</option>
                            <option value="100035">M36</option>
                            <option value="100039">TitanPaystack</option>
                            <option value="080002">Taj_Pinspay</option>
                            <option value="100027">Intellifin</option>
                            <option value="110001">PayAttitude Online</option>
                            <option value="110002">
                              Flutterwave Technology Solutions Limited
                            </option>
                            <option value="110003">Interswitch Limited</option>
                            <option value="110004">First Apple Limited</option>
                            <option value="110005">
                              3line Card Management Limited
                            </option>
                            <option value="110006">
                              Paystack Payment Limited
                            </option>
                            <option value="110007">Teamapt Limited</option>
                            <option value="110014">Cyberspace Limited</option>
                            <option value="110015">Vas2nets Limited</option>
                            <option value="110017">Crowdforce</option>
                            <option value="110032">Prophius</option>
                            <option value="090202">
                              Accelerex Network Limited
                            </option>
                            <option value="999999">NIP Virtual Bank</option>
                            <option value="110044">Leadremit Limited</option>
                            <option value="120001">
                              9Payment Service Bank
                            </option>
                            <option value="120002">HopePSB</option>
                            <option value="120003">MoMo PSB</option>
                            <option value="120004">SmartCash PSB</option>
                          </select>
                        </div>

                        <div>
                          <div className="py-2">
                            <input
                              className="h-18 bg-white appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-green-500"
                              type="text"
                              required
                              name="code"
                              placeholder="Account number"
                              onChange={(e) =>
                                setWithdrawal({
                                  ...withdrawal,
                                  nuban: e.target.value,
                                })
                              }
                            />
                          </div>

                          {withdrawal.verified && (
                            <div className="py-2">
                              <input
                                className="h-18 bg-white appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-green-500"
                                type="text"
                                required
                                name="code"
                                placeholder="Account name"
                                value={withdrawal.name}
                              />
                            </div>
                          )}
                        </div>

                        <div className="py-2">
                          <input
                            className="h-18 bg-white appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-green-500"
                            type="text"
                            required
                            name="code"
                            placeholder="Amount"
                            value={withdrawal.amount}
                            onChange={(e) =>
                              setWithdrawal({
                                ...withdrawal,
                                amount: e.target.value,
                              })
                            }
                          />
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {isVerifying && <i class="fas fa-spinner fa-pulse"></i>}
              {isWithdrawing && <i class="fas fa-spinner fa-pulse"></i>}

              <div className="px-4 py-3 sm:px-6 mb-4">
                <button
                  style={{ width: "94%" }}
                  type="button"
                  className="h-14 inline-flex items-center justify-center text-center rounded-md border border-transparent shadow-sm px-10 py-4 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-xs"
                  onClick={() => {
                    withdrawal.verified
                      ? makeWithdrawal()
                      : findVerifyWithdrawal();
                  }}
                >
                  {!withdrawal.verified ? "Verify account number" : "Withdraw"}
                </button>
              </div>
              <div className="px-4 py-3 sm:px-6 mb-4">
                {response !== "" && (
                  <p className="text-xs text-red-800 text-center mt-2">
                    {response}
                  </p>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
