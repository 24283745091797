import { selectToken } from "features/authentication/authSlice";
import React, { useEffect, useState } from "react";
import useFetch from "react-fetch-hook";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Inactive from "components/alerts/inactive";
import { getDownline, getUserDownline, getWalletSummary } from "./api";
import './index.css'
import '../../ui/modal/index.css'
import User from '../../assets/svg/UserCircle.svg'
import { Link } from 'react-router-dom'
import NaijaStates from "naija-state-local-government";

function Index() {
  const countOptions = [10, 20, 50, 100]
  const initFilter = {
    phone: "",
    district: "",
  }
  const ngStates = NaijaStates.states();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [filter, setFilter] = useState(initFilter);

  const profile = useSelector((state) => state.auth.profile);
  const token = useSelector((state) => state.auth.token);

  const [agents, setAgents] = useState([]);
  const [dWallets, setDWallets] = useState([]);

  const dispatch = useDispatch();

  let getDirectDownlines = async () => {
    setIsLoading(true)
    try {
      let response = await getUserDownline()
      if (response.status !== "SUCCESS") {
      } else {
        var _agents = response.data.direct
        _agents = [..._agents].sort(function (a, b) {
          return a.created_at - b.created_at;
        });
        setAgents(_agents)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  };

  function preparePages(agents, presentPage) {
    let _agents = [...agents].filter(
      e => e.msisdn.toLowerCase().includes(filter.phone.toLowerCase())
    )

    _agents = [..._agents].filter(
      e => e.district.toLowerCase().includes(filter.district.toLowerCase())
          )
    _agents = [..._agents].sort(function (a, b) {
      return a.created_at - b.created_at;
    });
    setTotal(_agents.length)
    var start = ((presentPage - 1) * pageCount);
    var end = start + pageCount;
    const listPage = _agents.slice(start, end);
    return listPage
  }


  useEffect(() => {
    getDirectDownlines();
    return () => { };
  }, []);

  function handlePageChange(p) {
    console.log(p)
    setPage(p + 1)
  }
  function handleCountChange(p) {
    setPageCount(p)
  }

  const [pagAgents, setPageAgents] = useState([]);
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(countOptions[0])
  const [total, setTotal] = useState(0)


  useEffect(() => {
    if (agents.length > 0) {
      const setTOshow = preparePages(agents, page)
      setPageAgents(setTOshow)
    }

  }, [page, pageCount, agents, filter])

  useEffect(() => {
    if (page != 1) {
      setPage(1)
    }

  }, [filter])

  return (
    <>
      {!profile.account_verified ? (
        <Inactive />
      ) : (
        <div className="flex items-center justify-between">
          <div className="flex flex-col lg:flex-row w-full items-start lg:items-center rounded bg-white ">
            <div className="w-full dark:bg-gray-800 mx-auto downline-center">

              <div className="flex justify-center self-center my-5">
                <div className="w-full md:w-2/3 flex justify-center self-center space-x-5">
                  <input placeholder="Search with phone and referral code" className="px-2"
                    onChange={(e) =>
                      setFilter({ ...filter, msisdn: e.target.value })
                    }
                  />
                  <select onChange={(e) =>
                    setFilter({ ...filter, district: e.target.value })
                  }>
                    <option defaultValue="">
                      All States
                    </option>
                    {ngStates.map((state, i) => (
                      <option value={state} key={i}>{state}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="flex justify-center self-center">
                <div className="w-full md:w-2/3 flex justify-center self-center flex-col">
                  <div>

                    {agents.length > 0 && <Pages
                      pageCount={pageCount}
                      current={page}
                      counts={countOptions}
                      handleOptChange={(cnt) => handleCountChange(cnt)}
                      handlePageChange={(p) => handlePageChange(p)}
                      total={total}
                    />
                    }
                  </div>

                  <table class="table-auto w-full text-center">
                    <thead class="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
                      <tr>
                        <th class="p-2 whitespace-nowrap">
                          <div class="font-semibold "></div>
                        </th> <th class="p-2 whitespace-nowrap">
                          <div class="font-semibold ">Phone</div>
                        </th>
                        <th class="p-2 whitespace-nowrap">
                          <div class="font-semibold">State</div>
                        </th>
                        <th class="p-2 whitespace-nowrap">
                          <div class="font-semibold">Joined</div>
                        </th>
                        <th class="p-2 whitespace-nowrap">
                          <div class="font-semibold "></div>
                        </th>
                      </tr>
                    </thead>
                    <tbody class="text-sm divide-y divide-gray-100">
                      {pagAgents.map((agent, i) => {
                        return <AgentItem key={i} index={i} agent={agent} token={token} addWallet={(e) => setDWallets([...dWallets, e])} />;
                      })}
                    </tbody>
                  </table>
                </div>
              </div>


            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Index;

function Pages({ current, pageCount, handlePageChange, counts, handleOptChange, total }) {
  return (
    <div>
      <div className="block text-sm pt-10 pb-10 overflow-scroll">
        {[...Array(Math.ceil(total / pageCount)).keys()].map((k, i) => <span className={`cursor-pointer rounded-lg px-3 p-2 mx-2 bg-gray-100  ${k + 1 === current && " bg-green-400 text-white"}`} onClick={() => { handlePageChange(k) }} key={i}>{k + 1}</span>)}
      </div>
      <span>
        <select onChange={(e) => handleOptChange(e.target.value)} className="p-2 border rounded-lg m-1 w-20">
          {counts.map(o => <option selected={o == pageCount} value={o}>{o}</option>)}
        </select>
      </span>
    </div>

  )
}


const AgentItem = ({ agent, token, index, addWallet }) => {
  return (
    <tr>
      <td class="p-2 whitespace-nowrap">
        <div class="">{index + 1}</div>
      </td> <td class="p-2 whitespace-nowrap">
        <div class="">{agent.msisdn}</div>
      </td>
      <td class="p-2 whitespace-nowrap">
        <div class="">{agent.district}</div>
      </td>
      <td class="p-2 whitespace-nowrap">
        <div class="">{new Date(agent.created_at * 1000).toLocaleDateString()}</div>
      </td>
    </tr>

  );
};
