import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import "./index.css";
import x from "../../assets/svg/X.svg";
import { useSelector } from "react-redux";
import NaijaStates from "naija-state-local-government";
import { addNewPlayer } from "./api";

export default function RegisterPlayerModal({ open, setOpen }) {
  const profile = useSelector((state) => state.auth.profile);
  const [response, setResponse] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const cancelButtonRef = useRef(null);
  const [updateUser, setUpdateUser] = useState({
    district: "",
    msisdn: "",
    agent: profile.phone || "nill",
  });

  useEffect(() => {
    if (profile.phone) {
      setUpdateUser({ ...updateUser, agent: profile.phone });
    }
  }, [profile]);

  async function AddPlayer() {
    if (
      !updateUser.agent ||
      !updateUser.district ||
      !updateUser.msisdn ||
      updateUser.msisdn.trim().length !== 11
    ) {
      alert("Invalid input");
      return;
    }
    setUpdateUser({
      ...updateUser,
      agent: profile.phone,
      msisdn: "234" + updateUser.msisdn.slice(1),
    });

    try {
      setIsLoading(true);
      let response = await addNewPlayer({
        agent: profile.phone || "",
        district: updateUser.district,
        msisdn: "234" + updateUser.msisdn.slice(1),
      });
      if (response.status === "SUCCESS") {
        setResponse("Added Successfully");
      } else {
        setError(response.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }
  const ngStates = NaijaStates.states();

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={() => {
          setResponse("");
          setError("");
          setOpen();
        }}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="flex items-start">
                  <div className="mt-3 sm:mt-0 sm:ml-4 sm:mr-4 w-full">
                    <img
                      className="close"
                      src={x}
                      onClick={() => setOpen(false)}
                      alt=""
                    />
                    <Dialog.Title
                      as="h3"
                      className="pt-10 text-lg leading-6 font-medium dialog--title"
                    >
                      Register Players
                    </Dialog.Title>
                    <div className="bg-white dark:bg-gray-800 rounded shadow">
                      <div className="flex justify-between w-full sm:w-full my-5 px-6 ">
                        <div className="flex w-full flex-col">
                          <div className="flex space-x-1 w-full">
                            <input
                              placeholder="Phone Number"
                              value={updateUser.msisdn}
                              onChange={(e) => {
                                setUpdateUser({
                                  ...updateUser,
                                  msisdn: e.target.value,
                                });
                              }}
                              className="border-0 p-2 placeholder-gray-500 text-gray-800 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                            />

                            <select
                              name="district"
                              className="border-0 p-2 placeholder-gray-500 text-gray-800 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                              id="district"
                              onChange={(e) => {
                                setUpdateUser({
                                  ...updateUser,
                                  district: e.target.value,
                                });
                              }}
                              autocomplete="district"
                              required
                              placeholder="State of residence"
                            >
                              <option disabled selected>
                                State of residence
                              </option>
                              {ngStates.map((state) => (
                                <option value={state}>{state}</option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {response && <p>{response}</p>}
                {error && <p>{error}</p>}
                {isLoading && (
                  <i class="fas p-3 text-2xl fa-spinner fa-pulse"></i>
                )}
              </div>
              <div className="px-4 py-3 sm:px-6 mb-4">
                <button
                  style={{ width: "94%" }}
                  type="button"
                  className="h-14 inline-flex justify-center rounded-md border border-transparent shadow-sm px-10 py-4 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => AddPlayer()}
                >
                  Add Player
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
